@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "HalvarEngschrift";
  src: url("./fonts/Halvar-Engschrift-Medium.ttf") format("woff"),
    /* Modern Browsers */ url("./fonts/Halvar-Engschrift-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HalvarMittelschrift";
  src: url("./fonts/Halvar-Mittelschrift-Regular.ttf") format("woff"),
    /* Modern Browsers */ url("./fonts/Halvar-Mittelschrift-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}

.font-HalvarEngschrift {
  font-family: "HalvarEngschrift";
}

.font-HalvarMittelschrift {
  font-family: "HalvarMittelschrift";
}

/* :root {
  cursor: none !important;
} */
.custom-px-13p {
  padding-left: 13%;
  padding-right: 13%;
}

body {
  margin: 0;
  font-family: "Halvar", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Halvar Engschrift", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  h1 {
    @apply text-[64px] lg:text-[110px] leading-[64px] lg:leading-[99px];
  }

  h2 {
    @apply text-4xl xl:text-[80px] xl:leading-[100px];
  }

  h3 {
    @apply text-[28px] lg:text-[64px] leading-[28px] lg:leading-[64px];
  }

  h4 {
    @apply text-[22px] lg:text-5xl leading-[100px];
  }

  h5 {
    @apply text-xl lg:text-3xl;
  }

  h6 {
    @apply text-base lg:text-2xl;
  }
}

@layer components {
  .viewContainer {
    @apply container max-w-full mx-auto px-[20px] md:px-[40px];
  }

  .h1-logo {
    display: block;
    background: url("./image/Frame.svg") no-repeat;
    text-indent: 101%;
    overflow: hidden;
    white-space: nowrap;
  }

  .btn-primary {
    @apply block text-center py-2 px-[20px] bg-primary text-support border border-primary cursor-pointer hover:bg-support hover:text-primary;
  }

  .primary-close:hover svg{
    stroke:#00FFFF
  }

  .btn-primary-header {
    @apply block text-center py-[10px] px-4 bg-primary text-support border overflow-hidden relative border-primary cursor-pointer hover:text-primary;
  }

  .btn-Secondary-header {
    @apply justify-center items-center gap-[5px] text-center py-[10px] px-4 border border-primary cursor-pointer bg-support text-primary overflow-hidden relative hover:text-support;
  }

  .btn-Secondary-header-icon {
    @apply w-[15px] h-[15px] mt-1 bg-[url('./image/arrow.svg')] bg-no-repeat bg-contain relative z-10;
    filter: #00FFFF;
  }

  .header-linear {
    width: 100%;
    background-image: url("./image/gradient.png");
    margin-top: -15px;
    background-size: cover;
    background-position: bottom;
    background-repeat: repeat-x;
    @apply h-[40px] lg:h-[70px]
  }

  .header-bg {
    @apply bg-support;
  }

  .headerNav {
    @apply text-primary font-normal hover:text-support hover:opacity-0;
    transition: all 0.5s ease-in-out;
  }

  .home-bg {
    width: 100vw;
    height: 100vh;
    background-image: url("./image/Landing.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .card-scroll {
    @apply h-screen flex justify-center md:items-center pt-[30%] md:pt-[40%] lg:pt-[0%] pb-[30%] md:pb-[0%];
  }

  .card-scroll-content {
    @apply bg-white px-[20px] pt-[20px] pb-10 flex flex-col items-center text-center md:px-10 md:h-screen md:pr-10 md:items-start md:w-full md:text-left md:pt-[30%] md:pl-0 lg:text-2xl text-base justify-center md:justify-start;
  }

  .card-scroll-content-title {
    @apply text-primary font-medium mb-4 text-3xl md:mb-8 md:text-5xl;
  }

  .card-scroll-content-txt {
    @apply text-center lg:text-2xl md:text-left md:pl-[60px] leading-normal;
  }

  [data-aos="card-scroll-content-txtHover"] {
    display: inline;
    background-repeat: no-repeat;
    background-image: linear-gradient(#00ffff 0%, #00ffff 100%);
    background-size: 0% 0%;
    /*transition*/
    transition: 0.4s ease;
    box-decoration-break: clone;
  }

  [data-aos="card-scroll-content-txtHover"].aos-animate {
    background-size: 100% 100%;
  }

  .lineHover:hover {
    @apply bg-contain;
  }

  .card-scroll-content-linear {
    width: 100%;
    height: 120px;
    background-image: url("./image/gradientB.png");
    background-repeat: repeat-x;
  }

  .gradientV {
    width: 160px;
    /* height: auto; */
    /* height: 100vh; */
    background-image: url("./image/gradientV.png");
    background-repeat: repeat-y;
    background-position: center;
    transform: translateX(-50%);
  }

  .marquee {
    @apply absolute top-0 z-50 overflow-hidden flex items-center justify-center mix-blend-difference;
  }

  .marquee-content {
    @apply relative left-0 text-[28px] md:text-4xl lg:text-[64px] grow-0 shrink-0 basis-auto inline-block py-5 lg:py-10;
  }

  .card-pt {
    @apply py-36 px-[10px] md:px-[40px] lg:px-[80px] flex gap-[20px] xl:gap-20;
  }

  .card-project {
    @apply text-white w-full sm:w-[280px] md:w-[420px] xl:w-[780px];
  }

  .card-project-title {
    @apply font-medium mb-4 md:text-[64px] lg:text-[110px] leading-normal;
  }

  .card-project-txt {
    @apply mb-[30px] w-full text-sm md:text-base;
  }

  .card-project-btn {
    @apply flex gap-[30px] text-primary;
  }

  .card-team {
    @apply w-[200px] md:w-[300px] lg:w-[400px];
    /* box-shadow: 0px 0px 60px 60px black; */
  }

  .card-team-img {
    @apply w-[200px] h-[240px] bg-cover bg-no-repeat mb-4 md:w-[300px] md:h-[360px] lg:w-[400px] lg:h-[400px] bg-[right_-18px_bottom] md:bg-[right_-30px_bottom] lg:bg-center;
    box-shadow: 0px 0px 60px 60px black;
  }

  .card-team-content {
    @apply mb-2 hidden justify-between items-center relative z-20;
  }

  .card-team-title {
    @apply text-[22px] md:text-4xl lg:text-5xl leading-none hidden relative z-20;
  }

  /* .card-team-icon {
    @apply flex gap-3 mt-1 lg:hidden;
  } */

  .card-team-icon-title-img {
    @apply w-4 h-4 md:w-7 md:h-7 cursor-pointer text-primary hover:text-support hover:opacity-0;
    transition: all 0.5s ease-in-out;
  }

  .card-team-icon-info-img {
    @apply w-4 h-4 md:w-6 md:h-6 cursor-pointer text-primary hover:text-support hover:opacity-0;
    transition: all 0.5s ease-in-out;
  }

  .card-team-info {
    @apply hidden relative z-20 flex;
  }

  .card-team-info-icon {
    @apply flex gap-5 mt-10 lg:mt-1 lg:w-[55%] justify-end;
  }
  
  .card-team-info-txt {
    @apply text-xs md:text-base lg:w-[50%] font-HalvarMittelschrift flex-1;
  }

  .btn-project {
    @apply md:text-[28px] lg:text-[30px] text-[22px] font-HalvarEngschrift flex items-center gap-2;
    transition: all 0.5s ease-in-out
  }

  .footer-txt {
    @apply cursor-pointer tracking-[1px] hover:text-support hover:opacity-0;
    transition: all 0.5s ease-in-out;
  }
}

.btn-project-icon {
  width: 0;
  height: 19px;
  background-image: url('./image/arrow.svg');
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.swiper-slide {
  max-width: 70% !important;
}

.swiper-slide-active .card-team-info {
  @apply lg:flex block;
}
.swiper-slide-active .card-team-title {
  @apply block font-HalvarEngschrift;
}
.swiper-slide-active .card-team-content{
  @apply flex;
}

.btnHidden{
  @apply hidden;
}

.GooeyCursor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  border-style: solid;
  border-width: 2px;
  border-color: #00ffff;
  border-radius: 30px;
  background-color: #00ffff;

  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  will-change: width, height, transform, border;
  z-index: 999;
  pointer-events: none;
}

.animationCard {
  background-color: #000000;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: top right;
  z-index: 1;
}

.animationCard-wrap {
  height: 280px;
  width: 280px;
  margin-bottom: 20px;
  position: relative;
  background-color: #000;
  background-size: contain;
  background-repeat: no-repeat;
  transform: matrix3d(1,0,0,
    calc(var(--mouse-x) / 3000),
    0,1,0,
    calc(var(--mouse-y) / 3000),
    0,0,1,0,0,0,0,1
  );
}

.animationCard-wrap-leave {
  height: 280px;
  width: 280px;
  margin-bottom: 20px;
  position: relative;
  background-color: #000;
  background-size: contain;
  background-repeat: no-repeat;
}

.animationCard-play {
  @apply w-10 h-10 md:w-11 md:h-11 lg:w-[60px] lg:h-[60px] !important;
  padding: 10px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00ffff;
  border: 1px solid #00FFFF;
  bottom: 24px;
  right: 24px;
  transform: translate(
    calc(var(--mouse-x) * -5px),
    calc(var(--mouse-y) * -5px)
  );
  transition: all 0.3s ease-in-out;
  /* background-image: url("./image/PolygonB.svg");
  background-repeat: no-repeat;
  background-position: center center; */
}
.animationCard-play:hover {
  background-color: #000000;
  opacity: 1;
}

.animationCard-play-icon {
  @apply bg-[length:17px_20px] md:bg-[length:19px_22px] lg:bg-[length:24px_28px];
  background-image: url("./image/PolygonB.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.animationCard-play-icon:hover{
  background-image: url("./image/Polygon.svg");
}

.animationCard-play-leave {
  height: 40px;
  width: 40px;
  padding: 10px;
  position: absolute;
  background-color: #00ffff;
  bottom: 24px;
  right: 24px;
  background-image: url("./image/PolygonB.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

@media (min-width: 1024px) {
  .animationCard-wrap,
  .animationCard-wrap-leave {
    height: 420px;
    width: 420px;
  }

  .animationCard-play,
  .animationCard-play-leave {
    height: 44px;
    width: 44px;
  }
}

@media (min-width: 1280px) {
  .animationCard-wrap,
  .animationCard-wrap-leave {
    height: 450px;
    width: 450px;
  }

  .animationCard-play,
  .animationCard-play-leave {
    height: 60px;
    width: 60px;
  }
}

.scroll {
  display: flex;
}
.scroll .text {
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1.2;
  @apply relative left-0 text-[28px] md:text-4xl lg:text-[64px] grow-0 shrink-0 basis-auto inline-block;
}
.scroll .text.animated {
  animation: scroll 90s linear infinite;
}
.scroll .text.animated:nth-child(2) {
  animation: scroll2 90s linear infinite;
  animation-delay: -45s;
}
@keyframes scroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes scroll2 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}
.gsap-container {
  overflow: hidden;
}

.product-img {
  width: 100%;
  opacity: 0;
  display: block;
}
.product-img.animated {
  opacity: 1;
}
.product-img-overlay {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}
.product-img-overlay::before {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: black;
  z-index: -1;
}

.product-img-overlay::before {
  transform: translateX(-100%);
  z-index: 1;
}
.product-img-overlay.left-animated:before {
  transform: translateX(0);
  transition: transform 0.4s ease;
}
.product-img-overlay.left-animated-2:before {
  transform: translateX(100%);
  transition: transform 0.4s ease;
}

.btn-Secondary-header svg:hover{
  fill: #000;
}

.btn-primary-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;    
  width: 100%;
  height: 100%;
  transform: translateX(-101%);
  background: #000000;
  transition: 0.25s ease-in-out;
}

.btn-Secondary-header:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;    
  width: 100%;
  height: 100%;
  transform: translateX(-101%);
  background: #00FFFF;
  transition: 0.25s ease-in-out;
}
  
.btn-primary-header:hover:before,.btn-Secondary-header:hover:before {
  transform: translateX(0);
}

.has-scroll-smooth body{
  overflow: hidden;
  /* position: relative;*/
  scroll-behavior: smooth; 
}

#homePage-container {
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

#entering-body {
  perspective: 400px;
  will-change: transform;
  transform-style: preserve-3d;
  /* perspective-origin: 50% 30%;
  transform: translate3d(0px,15%,0); */
}

.view-in .inButton {
  display: flex;
  opacity: 0;
  z-index: -30;
  transition: opacity 0.4s ease;
}
.view-in.show .inButton {
  display: flex;
  opacity: 1;
  transition: opacity 0.4s ease;
}
.view-in .inLogo {
  display: block;
  opacity: 0;
  z-index: -30;
  transition: opacity 0.4s ease;
}
.view-in.show .inLogo {
  display: block;
  opacity: 1;
  transition: opacity 0.4s ease;
}

@media (min-width: 768px) {
  .scrollDown-position {
    margin-left: calc(50% - 280px);
    margin-top: -100px;
    z-index: 7;
  }
  .custom-about-width {
    width: calc(100% - 62px);
    margin-left: auto;
  }
  .about-us {
    background-color: white;
    transform: translateX(120%);
  }
  .about-us-left {
    transform: translateX(50%);
  }
}

#canvas {
  background: rgb(0, 0, 0);
  user-select: none;
}

.bg-about {
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
}